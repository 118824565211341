import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectProps } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Image from "next/image";
import routes from "src/routes/main";

import { useAppSelector } from "~/state/hooks";

import useCopy from "~/modules/_core/locale";

import { getCountries } from "~/core/state/settingsSlice";

type CountryCallingCodeSelectProps = {
  required?: boolean;
  helperText?: string;
} & SelectProps;

const CountryCallingCodeSelect = (props: CountryCallingCodeSelectProps) => {
  const countries = useAppSelector(getCountries);
  const { translate } = useCopy();

  const { required: isRequired, helperText, ...selectProps } = props;
  const { error: hasError } = props;

  return (
    <FormControl
      variant="outlined"
      margin="normal"
      fullWidth
      required={isRequired}
      sx={{ marginY: 0 }}
    >
      <InputLabel id="user.country.code">
        {translate("field.country.callingCode")}
      </InputLabel>
      <Select
        labelId="country-code"
        id="country-code-select"
        size="medium"
        type="text"
        label={translate("field.country.callingCode")}
        inputProps={{
          sx: {
            display: "flex",
            fontSize: 18,
          },
        }}
        {...selectProps}
      >
        {countries &&
          Object.values(countries).map((country) => {
            return (
              <MenuItem
                key={country?.callingCode as string}
                value={country?.callingCode as string}
                divider
              >
                <Image
                  src={`${routes.flags}/${country.code}.svg`}
                  width={20}
                  height={20}
                  alt="country flag"
                />
                <Typography
                  sx={{
                    marginInlineStart: 1,
                  }}
                >{`(${country?.callingCode})`}</Typography>
              </MenuItem>
            );
          })}
      </Select>
      {hasError && helperText && (
        <FormHelperText error>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CountryCallingCodeSelect;

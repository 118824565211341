import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { differenceInSeconds } from "date-fns";

import { clearSessionData } from "~/utils/accessControl/useLogout";
import { regexNumbers } from "~/utils/constants/validationRegex";
import { convertNumbers2English } from "~/utils/convertArabicNumbersToEnglish";

import { useAppSelector } from "~/state/hooks";

import { OtpStatus } from "~/gql/main/types.generated";

import ErrorAlert from "~/modules/login/chunks/ErrorAlert";
import { phoneMinLength } from "~/modules/login/config";
import { useGetOtp } from "~/modules/login/helpers/useGetOtp";
import useCopy from "~/modules/login/locale";
import {
  getLastLoginAttempt,
  resetLastLoginAttempt,
} from "~/modules/login/state/otpConfigsSlice";

import FormLoadingButton from "~/core/components/Buttons/FormLoadingButton";
import CountryCallingCodeSelect from "~/core/components/CountryCallingCodeSelect";
import Container from "~/core/components/InitialScreenContainer";

const LoginForm = () => {
  const lastLoginAttempt = useAppSelector(getLastLoginAttempt);
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const { translate } = useCopy();
  const dispatch = useDispatch();

  const {
    getOtp,
    isLoading,
    constructQueryVariables,
    remainingSecondsToRetry,
    setRemainingSecondsToRetry,
    errorMessage,
    setErrorMessage,
  } = useGetOtp({
    phone: `${countryCode.substring(1)}${phoneNumber}`,
  });

  useEffect(() => {
    clearSessionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Check if there are any errors stored in redux that may have happened in the OTP verification page
  // that required re-direction to this page
  useEffect(() => {
    const { phone, errorCode, attempts, timer, timestamp } = lastLoginAttempt;
    if (phone && errorCode) {
      setCountryCode(`+${lastLoginAttempt?.phone?.substring(0, 3)}`);
      setPhoneNumber(lastLoginAttempt?.phone?.substring(3));

      if (timestamp) {
        // Required in case the user refreshed the page, so that the timer doesn't restart from the beginning
        const secondsElapsed = differenceInSeconds(
          new Date(),
          new Date(JSON.parse(timestamp))
        );

        if (secondsElapsed < timer) {
          setErrorMessage(
            translate(`error.status.${errorCode}`, {
              ...(errorCode === OtpStatus.MAX_ATTEMPTS
                ? {
                    maxAttempts: attempts,
                  }
                : {}),
            })
          );

          setRemainingSecondsToRetry(Number(timer - secondsElapsed));
        } else {
          setCountryCode("");
          setPhoneNumber("");
          dispatch(resetLastLoginAttempt());
        }
      } else {
        setErrorMessage(translate(`error.status.${errorCode}`));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLoginAttempt]);

  const submitLogin = (event: any) => {
    // prevent page refresh
    event.preventDefault();

    getOtp(constructQueryVariables());
  };

  const resetError = () => {
    setErrorMessage("");
    setRemainingSecondsToRetry(-1);
  };

  return (
    <Container>
      <Typography
        variant="h2"
        color="primary"
        sx={{
          textTransform: "uppercase",
          marginBottom: 2,
        }}
      >
        {translate("label.logIn")}
      </Typography>

      <Typography variant="subtitle1" sx={{ marginBottom: 6 }}>
        {translate("label.note.logIn")}
      </Typography>

      {errorMessage && (
        <ErrorAlert
          remainingSecondsToRetry={remainingSecondsToRetry}
          setRemainingSecondsToRetry={setRemainingSecondsToRetry}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
        />
      )}

      <form onSubmit={submitLogin}>
        <Grid
          container
          sx={{
            marginBottom: 6,
          }}
          spacing={1}
        >
          {/* Country Code */}
          <Grid item xs={5}>
            <CountryCallingCodeSelect
              type="number"
              value={countryCode}
              defaultValue={countryCode}
              onChange={(event) => {
                if (remainingSecondsToRetry > 0 || errorMessage) resetError();

                const code = event.target.value;
                setCountryCode(code as string);
              }}
            />
          </Grid>

          {/* phone */}
          <Grid item xs={7}>
            <TextField
              label={translate("field.phone")}
              value={phoneNumber?.replace(countryCode, "")}
              onChange={(event) => {
                if (remainingSecondsToRetry > 0 || errorMessage) resetError();

                const number =
                  event.target.value.length > 0 ? event.target.value : "";
                const output = convertNumbers2English(number);
                if (regexNumbers.test(output)) setPhoneNumber(output);
              }}
              variant="outlined"
              fullWidth
              InputProps={{ sx: { fontSize: 18 } }}
              helperText={
                phoneNumber &&
                phoneNumber.length < phoneMinLength &&
                translate("error.phoneNumberMin", { phoneMinLength })
              }
              error={!!(phoneNumber && phoneNumber.length < phoneMinLength)}
            />
          </Grid>
        </Grid>
        <FormLoadingButton
          isLoading={isLoading}
          // onClick={submitLogin}
          disabled={
            !countryCode ||
            !phoneNumber ||
            +phoneNumber.length < phoneMinLength ||
            remainingSecondsToRetry > 0
          }
        >
          {translate("label.logIn")}
        </FormLoadingButton>
      </form>
    </Container>
  );
};

export default LoginForm;

import type { NextPage } from "next";
import Router from "next/router";
import { ApolloProvider } from "@apollo/client";

import { useAppDispatch } from "~/state/hooks";

import { clientNoAuth } from "~/gql/main/apolloClient";

import Login from "~/modules/login/LoginForm";

import {
  GetCountriesForWebQuery,
  useGetCountriesForWebQuery,
} from "~/core/generated/main/get-many.generated";
import { setCountries } from "~/core/state/settingsSlice";
import routes from "~/routes/main";

type LoginType = {
  login: boolean;
};
const Home: NextPage<any> = ({ login }: LoginType) => {
  const dispatch = useAppDispatch();

  useGetCountriesForWebQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data: GetCountriesForWebQuery) => {
      dispatch(setCountries(data.getCountriesForWeb));
    },
  });

  if (!login) {
    Router.push(routes.tripManifest);
    return null;
  }

  return (
    <ApolloProvider client={clientNoAuth}>
      <Login />
    </ApolloProvider>
  );
};
// eslint-disable-next-line func-style
export async function getServerSideProps(cxt: any) {
  const serverRefreshToken = cxt.req
    ? cxt.req?.headers?.cookie?.split("saarDriverRefreshToken=")[1]
    : "";
  if (!serverRefreshToken) {
    return { props: { login: true } };
  }
  return { props: { login: false } };
}
export default Home;
